@font-face {
    font-family: 'dm_serif_textregular';
    src: url('./fonts/dm_serif/dmseriftext-regular-webfont.woff2') format('woff2'),
         url('./fonts/dm_serif/dmseriftext-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

* {-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
html {overflow-x: hidden;margin-right: calc(-1 * (100vw - 100%));}
body{font-family: 'Poppins'; font-weight:400; font-size:18px;line-height:1.2;color:#032cab; box-sizing:border-box;font-variant-ligatures: none;}
a {color: #032cab;}
a:focus,a:hover {color: #00d1ff;}
input,textarea,select,button {font-family: 'Poppins';color: #333;font-size: 13px;}
p {margin: 0;font-size: 20px;}
h1,h2,h3,h4,h5,h6 {margin-bottom: 0px;margin-top: 0px;font-variant-ligatures: none;}
h4,h5 {color:#031a7d;font-family: 'Poppins';letter-spacing:-1px;font-variant-ligatures: none;}
h2 {font-size: 42px;}
h4 {font-size: 32px;}
h5 {font-size: 20px;}


h2, h6 {
    font-family: "Poppins";
    color: #032cab;
    font-size: 40px;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 20px;
	font-variant-ligatures: none;
  }
  
 .on-image h2, h2.on-image, h6, .on-image p, .on-image li {
 	color:#ffffff;
 }
 
 .h-margin-bottom h1, .h-margin-bottom h2, .h-margin-bottom h3, .h-margin-bottom h4,.h-margin-bottom h5, .h-margin-bottom h6 {
 	margin-bottom: 50px;	
 }
 
 .light-font, .light-font h3, .light-font h4, .light-font h5, .light-font h2{
 	color:#fff !important;
 }
 
 .element-linebreaks p, .element-linebreaks h3, .element-linebreaks h4, .element-linebreaks h5{
 	margin-bottom:20px;
 }
  
 h3 {
    font-size: 30px;
    line-height: 1.2;
    font-family: "Poppins";
    font-weight: 400;
    color: #032cab;
    margin-bottom: 0;
	font-variant-ligatures: none;
  }

h1 {
	font-family: "dm_serif_textregular";
    color: #fff;
    font-size: 60px;
    line-height: 0.9;
    margin-bottom: 20px;
	letter-spacing:unset;
}

.on-image h1, h1.on-image{
	font-size: 60px;
	color: #fff !important;
    
}

.react-confirm-alert-body h1{
	color:#032cab;
}

@media (max-width:767px){
	h1 {font-size:48px;}
}


.on-image h3, h3.on-image, .on-image h2, h2.on-image {    
    color: #fff !important;
    margin-bottom: 50px;	
  }

img {max-width: 100%;}
.font-bold{font-family: 'Poppins';font-weight:bold;font-variant-ligatures: none;}
ul {margin-bottom: 0;}
li {margin-bottom:10px;}
a:hover {text-decoration: none;}
:focus {outline: none;}
.font-bold{font-family: 'Poppins';font-weight:bold;font-variant-ligatures: none;}
label{font-family: 'Poppins';font-weight: bold;font-size: 17px;font-variant-ligatures: none;}


ul ul {
    list-style-type: disc !important;
}

.image-gallery-image img {
max-width:unset !important;
max-height:50vh !important;
object-fit:scale-down !important;
}


.image-gallery-fullscreen-button::before, .image-gallery-play-button::before, .image-gallery-left-nav::before, .image-gallery-right-nav::before {
color:#031a7d !important;
text-shadow:unset !important;
}

/*start property info tabs*/
.property-info-tab{
  display:flex;
  flex-direction: column;
  align-items: center;
}
.property-info-tab{
  cursor: pointer;
  padding:10px 20px;
  margin-right:5px;
  background:#0352e0;
  display:inline-block;
  color:#fff;
  border-radius:3px;
}

.property-info-tab.active {
	background:#031a7d;
}

.property-info-panel{  
  animation: fadein .8s;
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}


/*end property info tabs */


.center 
{
	display:block;
	margin-left:auto;
	margin-right:auto;
}

.RichTextEditor__root___2QXK-
{
border: 1px solid #00d1ff !important;
box-shadow: none !important;
border-radius: 15px !important;
}

.skibtn {
    border: 0px;
    color: #000;
    display: inline-block;
    padding: 10px 24px;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 400;line-height: 1.42857143;text-align: center;white-space: nowrap;vertical-align: middle;-ms-touch-action: manipulation;touch-action: manipulation;cursor: pointer;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;
    user-select: none;background-image: none;border-radius: 30px;transition: all 0.5s ease-in-out;-webkit-transition: all 0.5s ease-in-out;-moz-transition: all 0.5s ease-in-out;-ms-transition: all 0.5s ease-in-out;
}
.skibtn a
{
	color: #031a7d;
}

.btn-cms {
    background-color: #00d1ff;
    color: #031a7d;	
    text-decoration: none;
	padding-top:22px;
	padding-bottom:22px;
}

.btn-on-image {	
	font-size:22px;	
    font-weight: 500;
    padding: 10px 30px;
    text-align: center;
    display: inline-block;
    color: #fff;
    background-color: #0152df;
}

.btn-on-image a{
	color: #fff;
}

.btn-on-image a:hover{
	color: #031a7d;
	background-color: #0152df !important;
}


.btn-secondary, .btn-general, .btn-general:focus, .btn-secondary:focus {
    background-color: #00d1ff;
    color: #ffffff;
    text-decoration: none;
}
.btn-secondary:disabled, .btn-general:disabled {
    background-color: #d2d2dc;
    color: #000;
    border: 0px;
}

.btn-regular {background-color: #00d1ff;color: #fff;}
.skibtn:hover{color: #000;}
.btn-general:hover, .btn-secondary:hover {background-color: #00d1ff;color:#031a7d;}
.btn-secondary:disabled:hover,.btn-general:disabled:hover{background-color: #d2d2dc;}
.pull-bottom {display: inline-block;vertical-align: bottom;float: none;}
.btn-danger {color: #fff;background-color: #031a7d;border-color: #031a7d;}
.btn-danger:hover, .btn-danger.focus, .btn-danger:focus, .btn-danger:active {background-color: #031a7d!important;border-color: #031a7d!important;}
.ml-5 {margin-left: 5px;}
a.btn-default1:hover {color: #fff;}
.pub-15{padding: 15px 0;}
.price-other-month
{
	background-color:#fff;	
	color:#777;
}

.image-gallery-image img
{
	max-width:750px;
	max-height:500px;
	object-fit:cover;
}

.image-gallery-thumbnail img
{
	max-width:92px;
	max-height:62px;
	object-fit:cover;
}

.price-calendar-row{
	text-align:center;
}

.price-calendar-row .week {
	width:10px;
	background-color:#777;
	color:#fff;
}

.price-calendar-row td {
	width:14%;
}
.Toast__toast___XXr3v{display: none!important;}
thead tr.price-calendar-row td{
	background-color:#777;
	color:#fff;
}
.uploader {
    display: block;
    clear: both;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
}
.uploader label {
    clear: both;
    width: 100%;
    padding: 2rem 1.5rem;
    text-align: center;
    background: #fff;
    border-radius: 7px;
    border: 3px solid #eee;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.uploader label:hover {
    border-color: #709faf;
}
.uploader label.hover {
    border: 3px solid #709faf;
    box-shadow: inset 0 0 0 6px #eee;
}
.uploader label.hover #start i.fa {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.3;
}
.uploader #start {
    float: left;
    clear: both;
    width: 100%;
}
.uploader #start.hidden {
    display: none;
}
.uploader #start i.fa {
    font-size: 50px;
    margin-bottom: 1rem;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.uploader #response {
    float: left;
    clear: both;
    width: 100%;
}
.uploader #response.hidden {
    display: none;
}
.uploader #response #messages {
    margin-bottom: 0.5rem;
}
.uploader #file-image {
    display: inline;
    margin: 0 auto 0.5rem auto;
    max-width: 180px;
}
.uploader #file-image.hidden {
    display: none;
}
.uploader #notimage {
    display: block;
    float: left;
    clear: both;
    width: 100%;
}
.uploader #notimage.hidden {
    display: none;
}
.uploader progress,
.uploader .progress {
    display: inline;
    clear: both;
    margin: 0 auto;
    max-width: 180px;
    border: 0;
    border-radius: 4px;
    background-color: #eee;
    overflow: hidden;
}
.uploader .progress[value]::-webkit-progress-bar {
    border-radius: 4px;
    background-color: #eee;
}
.uploader .progress[value]::-webkit-progress-value {
    background: -webkit-linear-gradient(left, #64b9d5 0%, #64b9d5 50%);
    background: linear-gradient(to right, #64b9d5 0%, #64b9d5 50%);
    border-radius: 4px;
}
.uploader .progress[value]::-moz-progress-bar {
    background: linear-gradient(to right, #64b9d5 0%, #64b9d5 50%);
    border-radius: 4px;
}
.uploader input[type="file"] {
    display: none;
}
.uploader div {
    margin: 0 0 0.5rem 0;
    color: #64b9d5;
}
.uploader .btn {
    display: inline-block;
    margin: 0.5rem 0.5rem 1rem 0.5rem;
    clear: both;
    font-family: inherit;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    text-transform: initial;
    border: none;
    border-radius: 0.2rem;
    outline: none;
    padding: 0 1rem;
    height: 36px;
    line-height: 36px;
    color: #fff;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    background: #64b9d5;
    border-color: #64b9d5;
    cursor: pointer;
}
.progress-bar {
    background-color: #709faf;
    color: #fff !important;
}
.fees-textfield {
    width: 15%;
    margin-left: 20px;
    border: 1px solid #00d1ff;
}
.form-row {
    margin-bottom: 15px;
}
.mr-10 {
    margin-right: 10px;
}
.navigable {
    cursor: pointer;
}
.row.table-row {
    margin-top: 5px;
    margin-bottom: 5px;
}
.tab-title {
    font-size: 22px;
}
.page-container {
    padding-top: 20px;
    padding-bottom: 20px;
}
.info-list-item {
    background-color: #fff;
    margin-bottom: 20px;
}
.alternate-color {background-color: aliceblue;}
.textarea-content {white-space: pre-wrap;}

.action-wrapper {
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
}
.list-text {
    padding-top: 2px;
    padding-bottom: 2px;
    word-break: break-all;
}
.list-text label {
    margin-right: 10px;
}
.item-body {
    padding: 15px;
}
.panel-group .panel {
    border-radius: 0;
    box-shadow: none;
    border-color: #d2d2dc;
}
.panel-default > .panel-heading {
    padding: 0;
    border-radius: 0;
    color: #212121;
    background-color: #d2d2dc;
    border-color: #d2d2dc;
}
.panel-title {
    font-size: 20px;
}
.panel-title > a {
    display: block;
    padding: 15px;
    text-decoration: none;
}
.more-less {
    float: right;
    color: #212121;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #eeeeee;
}
.accordion-panel .open {
    display: block;
}
.accordion-panel .close {
    display: none;
}
.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    z-index: 2;
    color: #fff;
    background-color: #64b9d5;
    border-color: #64b9d5;
}
.error {
    color: #a94442;
    font-size: 15px;
}
.panel-title > span.heading {
    display: block;
    padding: 15px;
    text-decoration: none;
}
.hide-search #search-bar,
.hide-search #result {
    display: none;
}
.show-search #search-bar {
    display: block;
}
.table-bordered > thead > tr > th {
    border-bottom-width: inherit;
}



.list-image img {
    object-fit: cover;
    height: 9em;
    margin-right: 10px;
    max-width: 13em;
    float: left;
}

#printable {
    display: none;
}
.mb-10 {
    margin-bottom: 10px;
}
.skeleton-line {
    display: inline-block;
    height: 100%;
    width: 100%;
    background: linear-gradient(-90deg, #c1c1c1 0%, #fff 50%, #c1c1c1 100%);
    background-size: 400% 400%;
    animation: pulse 1.2s ease-in-out infinite;
    padding: 20px 0 20px 0;
    margin: 10px 0 10px 0;
}
.skeleton-line::before {
    content: "\00a0";
}

.price-table-bordered>tbody>tr>td{
    border:1px solid #fff;
}

.price-table-bordered
{
font-size: 14px;
}

.green-bg {
    background-color: #7bb17a;
}
.pink-bg {
    background-color: #f29f48;
}
.gray-bg {
    background-color: gray;
}
.price-table-intersect1 {
    background-image: -webkit-linear-gradient(28deg, #f29f48 50%, #7bb17a 50%);
}
.price-table-intersect {
    background-image: -webkit-linear-gradient(28deg, #7bb17a 50%, #f29f48 50%);
}
.overview-table-container {
    width: 95%;
    margin: 0 auto;
    padding-bottom: 10px;
}
@keyframes pulse {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: -135% 0%;
    }
}
.tnc-link {
    color: #00abe3;
    cursor: pointer;
}
.form-control[disabled] {
    background-color: #00d1ff;
	color: #fff;
}
.modal-wrapper {
    padding: 20px;
}
.tnc-text {
    max-height: 300px;
    overflow-y: auto;
}
.checkbox_container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 400;
}
.checkbox-label{margin-left: 30px;}
.property-tnc.checkbox_container {
    font-size: 16px;
}
#infoModal {
    width: 100%;
    min-height: 400px;
}
#mapModal {
    width: 100%;
    min-height: 400px;
    max-width: 85%;
}
.info-loader {
    position: absolute;
    transform: translate(-50% ,-50%);
    top: 50%;
    left: 50%;
}
.checkbox_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
	border: 1px solid #00d1ff;
}
.checkbox_container input:disabled ~ .checkmark {
    background-color: #eee;
}
.checkbox_container input:checked ~ .checkmark {
    background-color: #00d1ff;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.checkbox_container input:checked ~ .checkmark:after {
    display: block;
}
.checkbox_container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkround {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-color: #00d1ff;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
}
.radio-wrapper {
    padding-bottom: 10px;
}
.modal-wrapper .modal-footer {
    padding-left: 0;
}
#google-map {
    position: relative;    
    height: 50vh;
    overflow: hidden;
}
#google-map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}
#google-map-filter {
    position: relative;
    padding-bottom: 37%;
    height: 0;
    overflow: hidden;
}
#google-map-filter iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}
.accordion .accordion-item .title {
    background-color: #fff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    font-size: 18px;
}
.accordion .accordion-item .title:after {
    content: "\2795";
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
}
.accordion .accordion-item .panel {
    max-height: 0;
    -webkit-transition: max-height 0.15s ease-out;
    -o-transition: max-height 0.15s ease-out;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}
.accordion .accordion-item.active .title:after {
    content: "\2796";
}
.accordion .accordion-item.active .panel {
    max-height: 1500px;
    -webkit-transition: max-height 0.25s ease-in;
    -o-transition: max-height 0.25s ease-in;
    transition: max-height 0.25s ease-in;
    overflow-y: auto;
}
.dest-acc-text {
    padding: 10px 0;
    word-break: break-all;
}
.dest-acc-content {
    padding: 0 20px;
}
.dest-acc-text .col-sm-3:nth-child(4n + 1) {
    clear: both;
}
.property-text-row .col-sm-6:nth-child(2n + 1) {
    clear: both;
}
.prop-acc-wrap {
    background-color: #ecf5f8;
    color: #005580;
    font-weight: bold;
}
.prop-acc-title {
    float: right;
    background-color: #005580;
    width: 45px;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
}
.prop-acc-img {
    width: 15px;
    margin-left: auto;
    margin-right: auto;
    transform: rotate(0deg);
}
.prop-acc-count {
    float: right;
    padding-top: 6px;
    padding-right: 6px;
    cursor: pointer;
}
.search-button {
    height: 100%;
    width: 100%;
    background-color: #64b9d5;
    border: none;
    color: #fff;
    font-size: 16px;
}
.duplicate-anchor {
    cursor: pointer;
    color: #23527c;
}
.duplicate-anchor1 {
    cursor: pointer;
    color: #337ab7;
    text-decoration: none;
}
.menu-wrap {
    padding: 5px 0px;
}
.menu-wrap a,
.menu-wrap i {
    color: #4a4a4a;
    font-size: 18px;
}
.search-properties-template {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
}
.footer-wrap {
    background-color: #d2d2dc;
    padding: 25px 0px;
}
.mr-5 {
    margin-right: 5px;
}
.property-search-row {    
    padding-top: 5px;
}
.feather-modal-wrapper {
    text-align: center;
    padding: 10px;
}
button.close {
    padding: 5px;
    color: #000;
    background: none;
    opacity: 1;
}
.lightbox {
    display: none;
}
.popup-link {
    margin-top: 20px;
    margin-bottom: 20px;
    word-break: break-all;
}
.full-width {
    width: 100%;
}
.prio-row {    
    padding: 15px 0;
}
.prio-property-header {
    font-size: 20px;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
    font-weight: bold;
}
.prio-row .slick-slide {
    padding: 5px;
}
.slick-track {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.container_wrap_logo {
    background: #0352e0;
}
.av-logo-container {
    height: 90px;
    line-height: 90px;
}
#header_main .logo {
    float: left;
    z-index: 1;
    position: absolute;
    padding: 12px 0;
    left: 0;
}
.logo,
.logo a {
    overflow: hidden;
    position: relative;
    display: block;
    height: 100%;
}
.logo img {
    padding: 0;
    display: block;
    width: auto;
    height: auto;
    max-height: 100%;
    image-rendering: auto;
    position: relative;
    z-index: 2;
    height: 100%\9;
    height: auto\9;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}
img,
a img {
    border: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    max-width: 100%;
    height: auto;
    image-rendering: optimizeQuality;
}
.main_menu {
    clear: none;
    position: absolute;
    line-height: 30px;
    height: 100%;
    margin: 0;
    z-index: 9;
    right: 0;
}
.av-main-nav-wrap {
    float: left;
    position: relative;
    z-index: 3;
}
nav.main_menu {
    width: 75%;
    float: right;
}
.av-main-nav li.menu-item {
    float: left;
    position: relative;
    z-index: 20;
}
.av-logo-container .av-main-nav > li > a, .av-logo-container .av-main-nav > li > span {
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    line-height: normal!important;
    vertical-align: bottom;
    display: table-cell;
    padding-bottom: 30px!important;
    height: 88px;
}
.av-main-nav > li > a, .av-main-nav > li > span {
    -webkit-transition: none;
    transition: none;
    -webkit-transition: background-color 0.4s ease-in-out,
        color 0.4s ease-in-out, border-color 0.4s ease-in-out;
    transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out,
        border-color 0.4s ease-in-out;
}
.av-main-nav > li > a, .av-main-nav > li > span {
    display: block;
    text-decoration: none;
    padding: 0px 20px 0px 5px;
    font-weight: normal;
    font-size: 12px;
    font-weight: 600;
    font-size: 13px;
    -webkit-transition: background-color 0.4s ease-in-out,
        color 0.4s ease-in-out, border-color 0.4s ease-in-out;
    transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out,
        border-color 0.4s ease-in-out;
}
li.menu-item:hover {
    border-bottom: 2px solid #00abe3;
}
.inner-container {
    position: relative;
    height: 100%;
    width: 100%;
}
.av-logo-container ul {
    list-style: none outside;
}
.av-main-nav li:hover {
    z-index: 100;
}
.av-main-nav li:hover .avia-menu-fx,
.current-menu-item > a > .avia-menu-fx,
.av-main-nav li:hover .current_page_item > a > .avia-menu-fx {
    opacity: 1;
    visibility: visible;
    background-color: #64b9d5;
    color: #ffffff;
    border-color: #4297b3;
    transition: all 0.3s ease-out;
    position: absolute;
    bottom: -1px;
    height: 2px;
    z-index: 10;
    width: 100%;
    left: 0;
}
.av-burger-menu-main {
    cursor: pointer;
}
.av-burger-menu-main {
    display: none;
    -webkit-transition: padding 0.3s ease-out;
    transition: padding 0.3s ease-out;
}
#header_main .av-main-nav > li.sub-menu > a {
    height: 90px;
}
.dropdown li:hover {
    background: #00d1ff;
    transition: background-color 500ms linear;
}

.no-hover:hover {
	background:#fff !important;
}

li.sub-menu a:hover, li.sub-menu a, li.sub-menu span.sub-menu-item {
    color: #031a7d;
	font-size: 15px;
    padding: 10px;
    display: block;
    cursor: pointer;
}
li.sub-menu ul.dropdown {
    min-width: 22rem;
    background: #fff;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
    position: absolute;
    z-index: 99999999;
    left: 0;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
}
li.sub-menu:hover ul.dropdown {
    visibility: visible;
    opacity: 1;
}
li.sub-menu ul.dropdown li {
    display: block;
}
.img-gallery {
    object-fit: cover;
    width: 120px;
    height: 120px;
    margin-right: 5px;
    margin-left: 5px;
}
.mb-15 {margin-bottom: 15px;}
.highlight a {background: #dcabab 50% 50% repeat-x !important;}
.payment-label {padding: 10px 24px;border-radius: 30px;font-size: 18px;font-weight: 400;line-height: 1.42857143;}
.highlight-book a {background: #bddbb2 50% 50% repeat-x !important;}
.highlight-sel {background: #e8dd65 50% 50% repeat-x !important;}
.ui-state-disabled.highlight span {background: #de8282 50% 50% repeat-x !important;color: black;}
.ui-datepicker-unselectable {opacity: 0.6 !important;}
.no-padding {padding: 0px !important;}
#top {margin-bottom: 10px;}
#top-menu {list-style-type: none;margin: 0;padding: 0;overflow: hidden;color: #333333;}
#top-menu li {float: left;}
#top-menu li a {
    display: block;
    color: #333333;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}
#top-menu li a:hover {
    background-color: #333333;
    color: #fff;
}
.info-icon {
    margin-right: 20px;
}
.info-icon img {
    width: 40px;
    float: left;
    margin-right: 10px;
}
.info-icon h4 {
    float: left;
    font-size: 22px;
}
.info {
    clear: both;
    padding-left: 52px;
}
.morecontent span {
    display: none;
}
.morelink {
    display: block;
}
.info-container {
    margin-bottom: 30px;
}
.container {
    margin: 0 auto;
}
ul.tabs {
    margin: 0px;
    padding: 0px;
    list-style: none;
}
ul.tabs li {
    background: none;
    color: #222;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
}
ul.tabs li.current {
    background: #ededed;
    color: #222;
}
.tab-content {
    padding: 15px;
}
.tab-content h1{
	color:#032cab;
}

.tab-content.current {
    display: inherit;
}
#auth-propertyData {
    margin-top: 15px;
}
#auth-data-header {
    font-weight: bold;
}
#auth-data-table {
    width: 100%;
}
#auth-data-table tr td {
    padding: 3px;
    margin: 0px;
}
#auth-data-table tr:nth-child(odd) {
    background-color: #ededed;
}
#auth-total-price {
    font-weight: bold;
}
.auth-property-wrapper {
    display: flex;
    margin-top: 10px;
}
#unreg-button {
    margin-top: 10px;
}
#close-button {
    margin-top: 0px;
    width: 100px;
    height: 40px;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
}
.auth-property-data {
    width: 50%;
}
#auth-property-descr {
    padding-left: 10px;
    padding-right: 10px;
}
.wrapper {
    min-height: 100vh;
    background-repeat: repeat;
    align-items: center;
    display: block;
}
.inner-auth {
    padding: 20px;
    background: #fff;
    max-width: 850px;
    margin: auto;
    display: none;
}
.container-center {
    text-align: center;
}
.container-inner-center {
    display: inline-block;
    margin: 0 auto;
}
.inner {
    padding: 20px;
    background: #fff;
    max-width: 70%;
    margin: auto;
    display: block;
    min-height: 100vh;
}
.inner .image-holder {
    width: 50%;
}
.form {
    width: 50%;
    padding-top: 36px;
    padding-left: 45px;
    padding-right: 45px;
}
.inner h3 {
    text-transform: uppercase;
    font-size: 25px;
    text-align: center;
    margin-bottom: 28px;
}

.form-group {
    display: flex;
}
.form-group input {
    width: 50%;
}
.form-wrapper {
    position: relative;
}
.form-wrapper i {
    position: absolute;
    bottom: 9px;
    right: 0;
}
.form-control {
    box-shadow: none;
    border: 2px solid #00d1ff;
    height: 45px;
    color: #031a7d;
}
.form-control::-webkit-input-placeholder {
    font-size: 13px;
    color: #333;
}
.form-control::-moz-placeholder {
    font-size: 13px;
    color: #333;
}
.form-control:-ms-input-placeholder {
    font-size: 13px;
    color: #333;
}
.form-control:-moz-placeholder {
    font-size: 13px;
    color: #333;
}
select option[value=""][disabled] {
    display: none;
}
.partner-logo-mob {
    display: none;
}
.partner-logo {
    position: absolute;
    right: 0;
    top: 10px;
    width: 200px;
}
.partner-logo img {
    max-height: 50px;
}
.media-partner-box {
    -webkit-box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.75);
    width: 30%;
    padding: 20px 20px 5px 20px;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 999;
    border-radius: 5px;
}
.media-partner-title {
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.media-partner-title-logo {
    max-height: 20px;
    margin-top: -5px;
}
.media-partner-desc {
    max-height: 100px;
    -webkit-transition: max-height 0.25s ease-in;
    -o-transition: max-height 0.25s ease-in;
    transition: max-height 0.25s ease-in;
    overflow-y: auto;
}
.media-partner-desc.inactive {
    max-height: 0;
    -webkit-transition: max-height 0.15s ease-out;
    -o-transition: max-height 0.15s ease-out;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}
.col-sm-12.text-right.logout-bar {
    padding: 20px;
}
.property-row {
    display: flex;
    flex-wrap: wrap;
}
.image-note {
    margin: 0 auto;
    max-width: 600px;
    padding-bottom: 20px;
    color: #a94442;
    font-size: 85%;
}
.col-centered {
    float: none;
    margin: 0 auto;
}
.col-centered .slick-prev {
    left: -35px;
}
.dynamic-content-area h1 , .content-heading {
    color: #031a7d;
}
.menu-icon {
    font-size: 20px;
    color: #00d1ff;
}
.menu-search-icon:before {
    content: "\f002";
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    color: #00d1ff;
}
.form-control[readonly] {
    background-color: #fff;
}
.property-image-area {
    float: right;
    margin: 10px;
}
.skinova-panel .panel.panel-default {
    border: none;
}
.skinova-panel .panel-heading {
    padding: 0;
    border-radius: 0;
    color: #fff;
    background-color: #00abe3;
    border-color: #00abe3;
}
.skinova-panel .panel-body {
    padding: 15px 0;
}
.book-icon-wrap span {
    position: absolute;
}
.book-icon-wrap {
    padding-bottom: 20px;
}
.book-icon {
    margin-right: 10px;
    font-size: 23px;
}
.book-icon-sec label {
    vertical-align: top;
}
.booking-number {
    font-size: 19px;
    padding-bottom: 10px;
}
  
.loading-btn .fa {
    margin-right: 8px;
}
.property-type-btn {float: left;}
.property-type-back{padding-right: 8px;}
ul.hyphen-style {
    list-style-type: none;
    padding-left: 1em;
  }
  
  ul.hyphen-style li:before {
    content: "–";
    position: absolute;
    margin-left: -1em;
}
.popup-header {
    padding: 0px 5px;
    border-bottom: 2px solid #00d1ff;
}
.mb-10 {
    margin-bottom: 10px;
}
.address-location {width: 600px;}
.Toastify__toast {
    padding: 30px!important;
    border-radius: 8px!important;
}
.Toastify__toast--success {
    background: #ffffff !important;
	color:#0352e0 !important;
}
.Toastify__toast-container--top-center{
	top:30% !important;	
}
.Toastify__close-button {
	color:#000 !important;
}
.nav>li.disabled>span {
    color: #777;
}
.nav-tabs>li.disabled>span {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}
.nav>li.disabled>span {
    position: relative;
    display: block;
    padding: 10px 15px;
    cursor: not-allowed;
}
.property-public-row:nth-child(2n + 1) {
    clear: both;
}
.has-error {
    border-color: #a94442;
}
.grid-p5 {
    padding: 0 5px;
}
.prio-prop-title {
    width: 70%;
    float: left;
    padding: 0px 20px;
}
.prio-prop-price {
    width: 30%;
    padding: 0 20px;
    float: left;
    text-align: right;
}
.prio-prop-header {
    font-weight: bold;
}
.footer-left-info{padding-top:120px;}
.footer-left-info h6{color:rgb(3,82,224);font-size:20px;margin-top:80px;}
.Footer-social-link{display:flex;margin-bottom:20px;max-width:190px;justify-content:center;}
.Footer-social-link li{list-style:none;}
.Footer-social-link li img{max-width:50px;margin-right:10px;}
.av-main-nav-wrap{display:flex;justify-content:center;width:100%;align-items:center;}
.footer-btn{height:40px;line-height:normal;display:flex;padding:0 30px;align-items:center;border:2px solid #dea303;color:#dea303;border-radius:2px;margin-left:20px;margin-top:10px;font-weight:600;}
.footer-btn:hover{background-color:#dea303;color:#ffffff;}
.upper-footer .col-sm-5 img {
    max-width: 580px;
    float: right;
    width: auto;
}
.upper-footer {
    position: relative;
    width: 100%;
    border-top: 50px solid rgb(3, 82, 224);
}
.slideup, .slidedown {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;
  }
.slidedown { max-height: 900px; }
.prop-info-table-cell, .prop-info-table-cell a {color: #fff!important;}
.prop-info-table-cell .prop-link {cursor: pointer;text-decoration: underline;}
@media (max-width:767px){
    .av-main-nav-wrap{float:left;position:relative;z-index:3;display:flex;justify-content:center;width:100%;align-items:center;flex-wrap:wrap;}
    .lower-footer{display:inline-block;width:100%;padding-bottom:20px;padding-top:20px;}
    .lower-footer nav ul li a{height:30px!important;}
    .lower-footer ul.menu .menu-item{width:100%;}
}
@media only screen and (min-width: 768px) {
    .main_menu {
        width: 80%;
        float: right;
    }
	.form-control {
    border-radius: 31px;
	}
}
@media screen and (min-width: 768px) and (max-width: 990px) {
    #header_main .logo {
        padding: 25px 0;
        width: 20%;
    }
    .upper-footer .col-sm-5 img {
        max-width: 361px;
    }	
    .av-logo-container {
        margin: 0 !important;
        width: auto;
    }
    .av_menu_right .main_menu {
        width: auto;
    }
    nav.main_menu {
        padding: 0px;
    }
    .av-main-nav > li {
        padding: 0 !important;
    }
    .av-main-nav > li:last-child {
        padding-left: 5px!important;
    }
    .av-main-nav > li > a {
        padding: 0px 0px 0 0;
    }
}
@media (max-width: 1199px) {
    .wrapper {
        background-position: right center;
    }
}
@media (max-width: 991px) {
    .inner .form {
        padding-top: 10px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .image-holder {
        display: none;
    }
    .inner .form {
        width: 100%;
    }
    .partner-logo {
        top: 0px !important;
    }
    .action-wrapper {position: static;}
    .mbr-991 {
        margin-bottom: 10px;
    }
}
@media (max-width: 767px) {

	.property-price-table
	{
		font-size:11px;
	}
	.property-price-table a {
		font-size:9px;
	}

	.col-xs-0 {display:none;}
	
    .address-location{width: 400px;}
    .selectboxit-container {
        width: 100% !important;
    }
    .selectboxit-container .selectboxit {
        width: 100% !important;
    }
    .inner {
        display: block;
        width: 100%;
        max-width: 100%;
    }
    .resp-hide {
        display: none;
    }
    .inner-auth {
        width: 100%;
    }
    .auth-property-data {
        width: 100%;
    }
    .auth-property-wrapper {
        display: block;
    }
    .wrapper {
        min-height: 70vh;
    }
    .inner .image-holder {
        width: 100%;
    }
    .inner .form {
        width: 100%;
        padding: 0px;
    }
    #filter-container .col-sm-3 {
        margin-bottom: 10px;
    }
    .slick-arrow {
        display: none !important;
    }
    .mbr15-767 {
        margin-bottom: 15px;
    }
    #header_main .logo {
        position: static;
        display: table;
        height: 80px !important;
        float: left;
        padding: 0;
        border: none;
        width: 45%;
    }
    #header_main .logo a {
        display: table-cell;
        vertical-align: middle;
    }
    .partner-logo-mob {
        display: inline-block;
        padding-top: 21px;
        width: 20%;
        font-size: 12px;
    }
    .partner-logo {
        display: none;
    }
    .media-partner-box {
        display: none;
    }
    .property-image-area {
        float: none;
    }
    .fees-textfield { 
        width: 25%;
    }
    .grid-p5 {
        padding: 0 15px;
    }
}
@media (max-width: 600px) {
    .fees-textfield {
        width: 55%;
        margin-left: 20px;
        display: block;
        margin: 11px 0;
        border: 1px solid #00d1ff;
    }
    .prio-prop-title {
        width: 100%;
    }
    .prio-prop-price {
        width: 100%;
        text-align: left;
    }
}
@media (max-width: 480px) {
    .mbr-480 {
        margin-bottom: 10px;
    }
    .partner-logo-mob {
        display: inline-block;
        width: 30%;
    }
    .address-location{width: 100%;}
}
@media (max-width: 400px) {
    .partner-logo-mob {
        display: inline-block;
        width: 35%;
    }
    .booking-dates-info {
        height: 80px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .av-logo-container {
        width: 100%;
    }
}
.gm-style-iw {
    width: 600px; 
    min-height: 150px;
}
.label-success {
    background-color: #032cab;
    border: 1px solid #032cab;
}
.label-icon {
    width: 20px;
    height: 16px;
    margin-right: 10px;
}

 

td.prop-info-table-cell.one-intersect {
    background-image: -webkit-linear-gradient(60deg, #7bb17a 50%, #f29f48 50%);
}
td.prop-info-table-cell.two-intersect {
    background-image: -webkit-linear-gradient(60deg, #f29f48 50%, #7bb17a 50%);
}
.button-icon {
    color: #032cab;
    font-size: 20px;
    float: right;
    background: none;
    border: none;
}
.error-border {
    border: 1px solid #a94442;
    border-radius: 7px;
    outline: none;
    border-color: #a94442;
    box-shadow: 0 0 10px #a94442;
}
.select-dropdown {
    border-radius: 31px;
    box-shadow: none;
    border: 2px solid #00d1ff;
    height: 45px;
    color: #031a7d;
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    background-color: #fff;
    background-image: none;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.select-dropdown .btn-dropdown {
    float: right;
}
.select-dropdown .dropdown-menu.open {
    display: block;
}