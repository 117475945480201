.search-accommodation-list {
    
}
@media (max-width: 1400px) {
    .search-accommodation-list {
        margin-top: 60px;
    }
}
@media (max-width: 1200px) {
    .search-accommodation-list {
        margin-top: 50px;
    }
}
@media (max-width: 768px) {
    .search-accommodation-list {
        margin-top: 40px;
    }
}
.search-accommodation-list .col-sm-6:nth-child(2n + 1) {
    clear: both;
}
.search-accommodation-list .search-accommodation-list-box {
    -webkit-box-shadow: 0px 2px 35px 0px rgba(151, 161, 178, 0.5);
    box-shadow: 0px 2px 35px 0px rgba(151, 161, 178, 0.5);    
    margin-bottom: 20px;
    padding: 34px;
}
@media (max-width: 1200px) {
    .search-accommodation-list .search-accommodation-list-box {
        padding: 24px;
    }
}
@media (max-width: 991px) {
    .search-accommodation-list .search-accommodation-list-box {
        padding: 15px;
    }
	
	.search-accommodation-list .search-accommodation-list-box .search-accommodation-list-img {
    margin-bottom:10px;
	width:100%;
	}
	
	.search-accommodation-list .search-accommodation-list-box .search-accommodation-list-img img {
		max-width:100% !important;
	}
}
.search-accommodation-list .search-accommodation-list-box .row {
    margin-left: -8px;
    margin-right: -8px;
}
.search-accommodation-list .search-accommodation-list-box .row .cols {
    padding-left: 8px;
    padding-right: 8px;
}
@media (max-width: 768px) {
    .search-accommodation-list .search-accommodation-list-box {
        margin-bottom: 40px;
    }
}
.search-accommodation-list .search-accommodation-list-box .search-accommodation-list-img {
    position: relative;
    float: left;
}
.search-accommodation-list .search-accommodation-list-box .search-accommodation-list-img img {
    object-fit: cover;
    height: 11em;
	width:100%;
    margin-right: 10px;
    max-width: 13em;
    float: left;
}
.search-accommodation-list .search-accommodation-list-box .blue-icon-text {    
	font-size: 17px;
    margin-bottom: 10px;
    font-weight: 700;
    word-break: break-all;
	color:#005aff;
}
.search-accommodation-list .search-accommodation-list-box h3 {
    
    font-size: 18px;
    line-height: 1;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
}
@media (max-width: 1400px) {
    .search-accommodation-list .search-accommodation-list-box h3 {
        font-size: 23px;
    }
}
@media (max-width: 1200px) {
    .search-accommodation-list .search-accommodation-list-box h3 {
        font-size: 23px;
        margin-bottom: 5px;
    }
}
@media (max-width: 991px) {
    .search-accommodation-list .search-accommodation-list-box h3 {
        font-size: 21px;
        margin-top: 10px;
    }
}
.search-accommodation-list .search-accommodation-list-box p {
    font-size: 17px;
    line-height: 1.5;    
    font-weight: 500;
    color: #032cab;
    margin-bottom: 0;
	height:48px;
	overflow:hidden;
}
@media (max-width: 768px) {
    .search-accommodation-list .search-accommodation-list-box p {
        font-size: 17px;
    }
}
.search-accommodation-list .search-accommodation-list-box p a {
    font-size: 15px;
    color: #00d1ff;
    display: inline-block;
}
.search-accommodation-list .search-accommodation-list-box p a:hover, .search-accommodation-list .search-accommodation-list-box p a:focus {
    font-size: 15px;
    color: #032cab;
    text-decoration: none;
}
.search-accommodation-list .search-accommodation-list-box .blueselect a {
    margin-top: 34px;
    text-align: center;
    background-color: #005aff !important;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    width: 100%;
    color: #fff;
    display: inline-block;
    padding: 10px;
}
.property-slick-image .slick-dots {
    bottom: 10px;
}
.slick-dots li button:before {
    font-size: 12px;
    color: #fff;
}
.property-slick-image .slick-prev {
    left: -1px;
    z-index: 9;
}
.property-slick-image .slick-next {
    right: 15px;
    z-index: 9;
}
.search-accommodation-list .search-accommodation-list-box h3.prop-title {
    height: auto;
}