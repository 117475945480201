@font-face {
  font-family: 'skinova';
  src: url('./fonts/fontello/skinova.eot?99296326');
  src: url('./fonts/fontello/skinova.eot?99296326#iefix') format('embedded-opentype'),
       url('./fonts/fontello/skinova.woff2?99296326') format('woff2'),
       url('./fonts/fontello/skinova.woff?99296326') format('woff'),
       url('./fonts/fontello/skinova.ttf?99296326') format('truetype'),
       url('./fonts/fontello/skinova.svg?99296326#skinova') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'skinova';
    src: url('../font/skinova.svg?99296326#skinova') format('svg');
  }
}
*/
 
[class^="ski-icon-"]:before, [class*=" ski-icon-"]:before {
  font-family: "skinova";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.ski-icon-skinova_ikon:before { content: '\61'; } /* 'a' */
.ski-icon-user-o:before { content: '\62'; } /* 'b' */
.ski-icon-kontakt_ikon:before { content: '\63'; } /* 'c' */
.ski-icon-stugagare_ikon:before { content: '\64'; } /* 'd' */
.ski-icon-calendar:before { content: '\65'; } /* 'e' */
.ski-icon-partner_ikon:before { content: '\66'; } /* 'f' */
.ski-icon-facebook:before { content: '\f09a'; } /* '' */
.ski-icon-instagram-1:before { content: '\f16d'; } /* '' */
.ski-icon-instagram-2:before { content: '\f31e'; } /* '' */
.ski-icon-instagram:before { content: '\f32d'; } /* '' */