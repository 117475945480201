.accordion-item:hover{
	cursor:pointer;
}

.accordion-item__icon {
    width: 2em !important;
    height: 2em !important;
    position: absolute !important;
    right: 0.1em !important;
}

.slick-prev:before,
.slick-next:before {
    font-size: 35px !important;
    color: gray !important;
}

.property-owner-info h1{
	margin-bottom:20px;
	margin-top:20px;
	color:#032cab;
}

.property-owner-info-provider h3{
	font-weight:bold;
}

.property-owner-info-provider h1,.property-owner-info-provider h3,.property-owner-info-provider div,.property-owner-info-provider p
{
	color:#fff;
}

.property-owner-info-provider-list-item, .property-owner-info-provider p{
	margin-top:7px;
	margin-bottom:7px;
	font-size:20px;
	font-weight:bold;
	letter-spacing:0.8px;
}

.property-owner-info-provider-list-item div:not(:first-of-type){
	text-align:center;
}

.property-owner-info-provider-list-header{
	text-align:center;
}

.property-owner-info-provider-list-item img{
	width:35px;
}

.property-owner h3
{
	font-weight:bold;	
	font-size:40px;
	line-height:52px;
}

.property-owner .column
{
	position:relative;	
	
}


.property-owner .col2
{
	padding:40px 7% 168px 7%;
}

.property-owner .col1
{
	padding:40px 7% 168px 7%;
}

@media (min-width: 1440px) 
{
	.property-owner .col2
	{
		padding:60px 10% 208px 10%;
	}
	
	.property-owner .col1
	{
		padding:60px 10% 208px 10%;
	}	
}


.property-owner-info-own{
	margin-top:40px;
}

.property-owner-info-own .list
{	
	margin-top:40px;	
}

.property-owner-info-own .list div
{
	
}

.property-owner-info-own-content{
	padding-top:20px;
}

.property-owner-info-own-content h3
{
	margin-bottom:20px;
}

@media (min-width: 990px) 
{
	.property-owner-info .skibtn
	{
	/*
		font-size:22px;
		padding:14px 28px;		
	*/
	}
}

@media (max-width: 990px) 
{
	.padding-full-width{
		padding-left:1em;
		padding-right:1em;
	}

	.property-owner-info .skibtn
	{
		margin-bottom:20px;
	}
}

.property-owner .skibtn
{
	position:absolute;
	bottom:60px;
	margin-top:98px;
	background-color: #0353e0; 
	height: 78px; 
	width: 212px; 
	border-radius: 212px; 
	text-align: left; 
	padding-top: 27px;
}

.property-owner .skibtn img
{
	width: 24px;
}

.property-owner .skibtn a
{
	color: rgb(255, 255, 255); 
	margin-left: 10px; 
	font-weight: bold;
}

.area-header {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
    word-break: break-all;
}
.large-campaign {
    display: flex;
}
.large-campaign .text-element {
    text-align: center;
}

.fullWidthOverlay p 
{
	font-size:18px;	
}

.fullWidthOverlay .title1
{
	font-size:36px;
	font-weight:bold;	
	font-family:'Poppins';
	font-variant-ligatures: none;
}

.fullWidthOverlay .content
{
	margin-top:10px;
	margin-bottom:15px;	
}

.fullWidthOverlay .title2
{
	font-size:36px;
	font-weight:bold;	
	font-family:'Poppins';
	font-variant-ligatures: none;
}

.multi-campaign-template h4 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.multi-campaign-type-Single .multi-campaign-image 
{
	display:flex;	
	max-width:100%;
	min-width:100%;
}

.multi-campaign-type-Double .multi-campaign-image 
{
	display:flex;
	float:right;
}
.multi-campaign-type-Double .multi-campaign-image img
{
	object-fit:cover;
	height:100%;
}

.multi-campaign-image-label
{
	width:100px;
	position:absolute;
	border-style: solid;
	border-width: 30px 20px 0 0;
	border-color: #0353e0 transparent transparent transparent;
}

.multi-campaign-image-label-text
{
	color:#fff;
	position:absolute;
	padding-left:5px;
	font-size:13px;
}


.content-template.padding-padding {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
}
.content-template.align-center {
    text-align: center;
}
.reverse {
    flex-direction: row-reverse;
}
.content-section .reverse .image-element {
    padding-left: 0px;
    padding-right: 0px;
}
.stdbtn {
    border: 2px solid #00abe3;
    background-color: #00abe3;
    color: #fff;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}
.stdbtn a {
    color: #fff !important;
}

.columns-template .block-img {
    padding-bottom: 10px;
}

.columns-template .button-margin .skibtn{
	margin-top:20px;
}

.row.equal {
    display: flex;
    flex-wrap: wrap;
}
.content-section .image-element {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
    padding-left: 0px;
}
#footer-container p {
    margin-bottom: 10px;
}
.large-campaign-template {
    margin-top: 50px;
    margin-bottom: 50px;
}
.content-template p {
    margin-bottom: 10px;
	display:flow-root;
}
.content-section .img-wrap {
    width: 100% !important;
    height: 100% !important;
}
.content-section .image-element img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}
.content-section .text-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.resort-country {
    float: left;
    height: 20px;
    margin-right: 5px;
}
.align-center {
    text-align: center;
}

@media (max-width: 767px) {

	.fullWidthOverlay .title2{
		display:block;
	}

    .area-header {
        clear: both;
        padding-top: 10px;
    }
    .large-campaign {
        display: block;
    }
    .content-section .image-element {
        padding-left: 0px;
        padding-right: 0px;
    }
    .content-section .text-element {
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .multi-campaign-template h2 {
        /*padding: 20px 0px 0px 30px;*/
        font-size: 30px;
    }
    .dynamic-content-area h2 {
        font-size: 30px;
    }
    .search-properties-template h1 {
        font-size: 30px;
    }
    .reverse {
        flex-direction: row-reverse;
        display: block;
    }
    .more {
        clear: both;
    }
}
@media (max-width: 600px) {
    .img-col, .text-col {
        width: 100%;
        margin-bottom: 10px;
    }
    .random-properties-item .image {
        width: 100%;
        height: 100%;
    }
} 
  .accordion-list {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border-radius: 0.4rem;
    overflow: hidden;
    box-shadow: 0 0 0.8rem 0.1rem rgba(15, 72, 179, 0.06), 0 20px 30px -10px rgba(15, 72, 179, 0.2);
  }
  .accordion-list__item + .accordion-list__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .accordion-item--opened .accordion-item__icon {
    transform: rotate(180deg);
  }
  .accordion-item--opened .accordion-item__inner {
    max-height: 100rem;
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition-duration: 0.5s;
    transition-property: max-height;
  }
  .accordion-item--opened .accordion-item__content {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: opacity, transform;
  }
  .accordion-item__line {
    display: block;
    padding: 0.8rem 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index: 2;
    position: relative;
  }
  .accordion-item__title {
    font-size: 1.6rem;
    margin: 0;
    font-weight: 700;
    color: #121212;
  }
  .accordion-item__icon {
    width: 1.2rem;
    height: 1.2rem;
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
    opacity: 0.6;
  }
  .accordion-item__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
  }
  .accordion-item__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: 'transform';
    transition-delay: 0.5s;
    padding: 0 1.2rem 1.2rem;
  }
  .accordion-item__paragraph {
    margin: 0;
    color: #333;
    font-weight: 300;
    line-height: 1.3;
  }
  