
.radio input, .radio-inline input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.radio, .radio-inline {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
}
.radio {
    padding-bottom: 10px;
}
.radio input:checked~.checkround:after, .radio-inline input:checked~.checkround:after {
    display: block;
}
.radio .checkround:after, .radio-inline .checkround:after {
    left: 11px;
    top: 10px;
    transform: translate(-50%,-50%);
    content: '';
    width: 10px;
    height: 10px;
    font-size: 12px;
    background-color: #00d1ff;
    border-radius: 50px;
}
.checkround:after {
    content: "";
    position: absolute;
    display: none;
}