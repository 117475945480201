div#search-bar {
    background-image: linear-gradient(
            0deg,
            rgba(32, 17, 97, 0.2),
            rgba(72, 46, 223, 0.2)
        ),
        url(/images/search-bg.jpg);
    background-size: cover;    
}
div#search-bar-ebooking {
	background-color:#c0c0c0;
}
.search-container select {
    height: auto;
}
.search-filter-wrapper .form-group {
    margin-bottom: 0px;
}
.search-filter-wrapper .row-flex {
    display: flex;
    border-radius: 50px;
    overflow: hidden;
}
.search-filter-wrapper .col {
    width: 100%;
    position: relative;
    margin: 0px 2px;
    border-radius: 5px;
}
.search-filter-wrapper .row-flex .col:nth-child(1) {
    border-radius: 50px 5px 5px 50px;
}
.search-filter-wrapper .col.border-right {
    background-color: #fff;
}
.search-filter-wrapper .col .form-group {
    padding: 5px 4px 15px 20px;
    display: inline-block;
    width: 100%;
}
.search-filter-wrapper .col .form-group label {
    width: 100%;
    display: inline-block;
    line-height: 18px;
}
.search-filter-wrapper .col .form-group select {
    background-color: transparent !important;
    margin: 0px !important;
    border: 0px !important;
    padding: 0px !important;
    -webkit-appearance: none !important;
}
.search-filter-wrapper .col .form-group .input-group {
    position: relative;
    display: inline-block;
    width: 100%;
}
#search-logos {
    margin-top: 20px;
    margin-bottom: 20px;
}
select.color-change option {
    color: #000;
}
select.color-change:not(:checked) {
    color: #0059ff;
}
.color-change {
    color: #0059ff;
    font-weight: bold;
    white-space: normal;
    font-size: 14px;
}
.search-filter-wrapper .col .form-group .input-group input {
    margin-bottom: 0px !important;
    padding: 0px !important;
    background-color: transparent !important;
    border: 0px !important;
    width: 100%;
    max-width: 100px;
    height: auto !important;
    box-shadow: none !important;
}
.search-filter-wrapper .col .form-group .input-group i {
    position: absolute;
    bottom: 4px;
    cursor: pointer;
}
.search-filter-wrapper .col .form-group.form-submit-btn .btn-submit-wrap {
    background: #0351e0;
    border: 0;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px 50px 50px 5px;
    cursor: pointer;
}
.search-filter-wrapper .col .form-group.form-submit-btn .btn-submit-wrap span {
    font-size: 12px;
    display: inline-block;
    margin-right: 5px;
}
.search-filter-wrapper .col .form-group.form-inline {
    display: flex !important;
    height: 100%;
    align-items: center;
    padding-right: 10px;
}
.search-filter-wrapper .fa-arrow-right {
    position: absolute;
    left: -20px;
    width: 40px;
    height: 27px;
    background-color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 27px;
}
.search-filter-wrapper .col .form-group.form-inline .input-group {
    max-width: 80px;
}
.search-filter-wrapper .col .form-group.form-inline input {
    max-width: 50px !important;
    padding-right: 10px !important;
}
.search-filter-wrapper .col .form-group .input-group-select i {
    position: absolute;
    top: 50%;
    right: 30%;
    font-weight: 600;
}
.search-filter-wrapper .col .form-group.form-select-wrap {
    position: relative;
    width: 100%;
}
.search-filter-wrapper .col .form-group.form-select-wrap select {
    width: 100%;
}
.text-below-search {
    margin-bottom: 6%;
}
.searchbox-select::after {
    position: absolute;
    content: "";
    background: url(/images/br_down.png) no-repeat right rgba(0, 0, 0, 0) !important;
    width: 20px !important;
    height: 20px !important;
    right: 5px;
    top: 2px;
    pointer-events: none !important;
}
.search-wrapper label {
    position: absolute;
    margin-top: 5px;
}
.search-filter-wrapper .col .form-group select {
    padding-top: 25px !important;
    cursor: pointer !important;
}
.input-group-select.searchbox-select {
    position: relative;
    cursor: pointer !important;
}
.result-img-copyright {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    padding-left: 3px;
    bottom: 0px;
    font-size: 12px;
    color: #000;
    width: 100%;
}
.search-logo-container {
    margin: 10px;
    padding: 10px;
    display: inline-block;
}
.search-logo-img {
    height: 70px;
    max-width: 13em;
}
.search-logo-img-copyright {
    background-color: #fff;
    padding-left: 3px;
    font-size: 12px;
    color: #000;
    width: 100%;
}
.resort-logo {
    float: right;
    height: 50px;    
    margin-left: 5px;
}
.property div a {
    color: black;
    text-decoration: none;
}
.property-name {
    float: left;
    width: 40%;
}
.property-beds {
    float: left;
    width: 20%;
}
.property-price {
    float: left;
    width: 20%;
}
.property-link {
    float: left;
    width: 20%;
    text-align: right;
}
.prio-properties-container {
    display: block;
    margin-top: 5px;
}
.properties-prio-header {
    color: #fff;
    font-weight: normal;
    letter-spacing: 1px;
    background-color: #3c763d;
    padding: 3px;
}
.more-properties {
    margin: 0px;
    margin-top: 10px;
    float: right;
    height: 34px;
    display: none;
}
.prio-properties-header {
    font-weight: bold;
    background-color: #cedece;
    color: #000;
}
.properties-header {
    font-weight: bold;
    background-color: #005580;
    color: #fff;
}
.properties-header div {
    padding: 3px;
}
.property {
    padding: 3px;
}
.property:nth-child(odd) {
    background-color: #ecf5f8;
}
.result-img {
    object-fit: cover;
    height: 9em;
    margin-right: 10px;
    max-width: 13em;
    float: left;
}
.ui-datepicker {
    font-size: 18px;
}
.search-container {
    margin: 3rem auto;
    max-width: 815px;
}
.lnr-calendar-full {
    cursor: pointer;
}
h1.title-above-search {
    text-align: center;
    margin-top: 8%;
    color: #fff;    
},
h2.title-above-search {
    text-align: center;
    margin-top: 8%;
    color: #fff;
    font-size: 48px;
}
h4.text-below-search {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: #fff;
}
.property-sort-row {    
    padding: 20px 0;
}
.property-filter-wrapper {
    padding: 50px 0 20px 0;
}

.search-skeleton {
    width: 100%;
    height: 150px;
    border-top: 15px solid #f1f1f1;
    border-bottom: 15px solid #f1f1f1;
}
.search-skeleton .avatar {
    float: left;
    width: 100px;
    height: 100px;
    background-color: #ccc;
    border-radius: 25%;
    margin: 8px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: shine-avatar 1.6s infinite linear;
}
.search-skeleton .lines {
    float: left;
    width: 60%;
}
.search-skeleton .line-title {
    width: 30%;
    height: 16px;
    margin-top: 12px;
    border-radius: 7px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: shine-lines 1.6s infinite linear;
  }
.search-skeleton .line-desc {
    width: 60%;
    height: 16px;
    margin-top: 12px;
    border-radius: 7px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: shine-lines 1.6s infinite linear;
}
.search-skeleton .line ~ .line {
    background-color: #ddd;
}
.resort-heading {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
    word-break: break-all;
}
  
@keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%, 100% {
      background-position: 140px;
    }
}
@keyframes shine-avatar {
    0% {
      background-position: -32px;
    }
    40%, 100% {
      background-position: 208px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .container.av-logo-container {
        width: 100%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .container.av-logo-container {
        width: 100%;
    }
    #header_main .av-main-nav > li > a {
        font-size: 14px;
    }
    .search-filter-wrapper {
        padding: 30px 0px !important;
        display: inline-block;
        width: 100%;
    }
    .search-filter-wrapper .row-flex {
        border-radius: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .search-filter-wrapper .row-flex .col {
        width: 33.33% !important;
    }
    .search-filter-wrapper .col .form-group.form-submit-btn .btn-submit-wrap {
        border-radius: 0px 0px 20px 0px !important;
    }
    .search-filter-wrapper .row-flex .col:nth-child(3) {
        border: 0px;
    }
    .search-filter-wrapper .row-flex .col:nth-child(4),
    .search-filter-wrapper .row-flex .col:nth-child(5) {
        border-top: solid 1px #555;
    }
    .search-filter-wrapper .row-flex .col:nth-child(5) {
        border-right: 0px !important;
        width: 67% !important;
    }
    .search-filter-wrapper .col .form-group {
        padding: 1px 20px !important;
    }
    .search-filter-wrapper .col .form-group.form-inline {
        padding-left: 25px;
    }
    .search-filter-wrapper .col .form-group.form-select-wrap {
        padding-right: 25px !important;
    }
    /*designer code here */
    .search-filter-wrapper .row-flex .col:nth-child(1) {
        border-radius: 5px !important;
    }
    .search-filter-wrapper .row-flex {
        border-radius: 15px !important;
    }
    .search-filter-wrapper .col .form-group.form-submit-btn .btn-submit-wrap {
        position: initial !important;
        height: 50px !important;
        border-radius: 0px 0px 15px 15px !important;
    }
    .search-filter-wrapper .col .form-group.form-submit-btn {
        padding: 1px 0px !important;
    }
    .search-filter-wrapper .col .form-group.form-submit-btn .btn-submit-wrap {
        width: 100% !important;
    }
    .search-filter-wrapper .col {
        margin: 0 1px !important;
    }
    .search-filter-wrapper .row-flex .col:nth-child(3) {
        border-top: 1px solid #555 !important;
    }
    .searchbox-select::after {
        right: -8px !important;
    }
}

@media only screen and (max-width: 767px) {
    .search-filter-wrapper .row-flex {
        flex-wrap: wrap;
        border-radius: 30px;
    }
    .search-filter-wrapper .col.border-right {
        border-right: 0px !important;
        border-bottom: solid 1px #555;
    }
    .search-filter-wrapper .col .form-group.form-submit-btn {
        padding: 0px !important;
    }
    .search-filter-wrapper .col .form-group.form-submit-btn .btn-submit-wrap {
        position: initial !important;
        height: 50px !important;
        border-radius: 0px 0px 30px 30px !important;
    }
    .search-filter-wrapper .col .form-group.form-inline {
        display: flex !important;
        height: 100%;
        align-items: center;
    }
    .search-filter-wrapper .fa-arrow-right {
        opacity: 0;
    }
    .search-container {
        width: 100% !important;
        max-width: 100% !important;
    }
    .search-filter-wrapper {
        padding: 15px 5px;
    }
    .quantity__minus,
    .quantity__plus {
        width: 100px;
        padding: 0 10px;
    }
    /*designer code here */
    .search-filter-wrapper .row-flex .col:nth-child(1) {
        border-radius: 5px !important;
    }
    .search-filter-wrapper .row-flex {
        border-radius: 15px !important;
    }
    .more-properties {
        margin: 0px;
        margin-top: 10px;
        float: none;
        display: none;
    }
    #filter-container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .resort-logo {
        float: none;
        margin: 10px 0;
    }
    .resort-heading-wrapper {
        clear: both;
        padding-top: 10px;
    }
}
.error-mark {
    border: 1px solid red !important;
}