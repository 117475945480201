footer .container {
	max-width: 1450px;
	width: 100%;
}
footer {
	background-color: #02197c;
	padding: 115px 0 80px;
	color: #fff;
	position: relative;
	overflow: hidden;
  }
@media (max-width: 1400px) {
	footer {padding: 90px 0 70px;}
}
@media (max-width: 1200px) {
	footer {padding: 80px 0 60px;}
}
@media (max-width: 991px) {
	footer {padding: 70px 0 50px;}
}
@media (max-width: 768px) {
	footer {padding: 50px 0 40px;}
}
footer .sk-right-logo-icon {
	position: absolute;
	right: -60px;
	top: 0;
	width: 28%;
	bottom: 0;
	margin: auto;
}
@media (max-width: 1400px) {
	footer .sk-right-logo-icon {width: 32%;}
}
footer .sk-footer-logo {
	max-width: 350px;
	display: inline-block;
	margin-bottom: 10px;
	width: 100%;
}
@media (min-width: 1300px) {
	footer .sk-footer-logo {max-width: 470px;}
}
footer .sk-footer-logo img {max-width: 100%;}
.sk-footer-info p {
	font-size: 18px;
	max-width: 450px;
	margin-bottom: 35px;
	margin-top: 5px;
	font-weight: 600;
}
@media (max-width: 991px) {
.sk-footer-info p {font-size: 16px;}
}
@media (max-width: 550px) {
.sk-footer-info p {margin: 20px auto;}
}
.sk-footer-info h5 {
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 30px;
}
@media (max-width: 991px) {
.sk-footer-info h5 {font-size: 16px;}
}
.sk-footer-info ul {
	padding: 0;
	margin: 0;
}
.sk-footer-info ul li {display: inline;}
.sk-footer-info ul li a {
	display: inline-block;
	margin-right: 20px;
}
.sk-footer-info ul li a img {max-width: 64px;}
.sk-footer-menu {
	display: flex;
	width: 100%;
	margin-top: 70px;
	align-items: center;
}
@media (max-width: 1300px) {
	.sk-footer-menu {margin-top: 50px;}
}
@media (max-width: 1200px) {
	.sk-footer-menu {margin-top: 40px;}
}
.sk-footer-menu ul {
	padding: 0;
	margin: 0;
	display: flex;
}
.sk-footer-menu ul li {
	list-style: none;
	font-size: 20px;
}
.sk-footer-menu ul li a {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: #fff;
	padding-right: 30px;
	font-size: 16px;
	font-weight: 500;
	text-decoration: none;
}
@media (min-width: 1200px) {
	.sk-footer-menu ul li a {font-size: 20px;}
}
.sk-footer-menu ul li a:hover {
	text-decoration: none;
	color: #00d1ff;
}
.sk-footer-menu ul li a img {margin-right: 10px;}
.sk-login-btn {
	height: 45px;
	font-size: 20px;
	width: 130px;
	border: 2px solid #00d1ff;
	border-radius: 30px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff !important;
	transition: all 0.2s linear;
	text-decoration: none !important;
}
@media (min-width: 1300px) {
	.sk-login-btn {
		border: 2px solid #00d1ff;
		height: 50px;
		font-size: 20px;
		margin-right: 20px;
		margin-left: 20px;
		width: 150px;
	}
}
.sk-login-btn:hover {
	background-color: #00d1ff;
	border: 2px solid #00d1ff;
	color: #fff !important;
	text-decoration: none !important;
}
.sk-air-info {
	margin-left: 30px;
	display: flex;
}
.sk-air-info small {
	font-size: 16px;
	font-weight: 600;
	margin-left: 20px;
}
@media (max-width: 991px) {
	footer .sk-right-logo-icon {width: 290px;}
	footer .sk-footer-logo {max-width: 300px;}
	.sk-footer-info p {font-size: 14px;}
	.sk-footer-info ul li a img {max-width: 50px;}
	.sk-login-btn {
		height: 40px;
		font-size: 20px;
		font-weight: 500;
		width: 140px;
	}
}
@media (max-width: 991px) {
	.sk-footer-menu {flex-wrap: wrap;}
	.sk-footer-menu ul {
		width: 100%;
		margin-bottom: 25px;
	}
}
@media (max-width: 667px) {
	footer .sk-right-logo-icon {width: 220px;}
}
@media (max-width: 550px) {
	footer .sk-right-logo-icon {display: none;}
	.sk-footer-menu ul {flex-wrap: wrap;}
	.sk-footer-menu ul li {
		width: 100%;
		text-align: center;
	}
	.sk-footer-menu ul li a {
		width: 100%;
		text-align: center;
		justify-content: center;
		margin-bottom: 15px;
	}
	.sk-footer-info {text-align: center;}
	.sk-login-btn {
		width: 100%;
		margin-bottom: 30px;
	}
	.sk-air-info {
		margin: 0 auto;
		display: flex;
	}
}