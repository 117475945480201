@import url('https://fonts.googleapis.com/css?family=Raleway:400, 700, 800');
.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}
.right .bm-burger-button {
    left: initial;
    right: 36px;
}
.bm-burger-bars {
    background: #709faf;
    height: 10% !important;
}
.bm-morph-shape {
    fill: #709faf;
}
.bm-menu {
    background: #ffffff;
}
.bm-menu a, .bm-menu span.menu-item {
    color: #4a4a4a;
    font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    font-size: 14px;
    text-decoration: none;
}
.bm-item-list a, .bm-item-list span.menu-item {
    padding: 0.8em;
    border-bottom: 1px solid #ebebeb;
}
.bm-item-list a span {
    margin-left: 10px;
    font-weight: 700;
}
.bm-item:focus {
    outline: none;
}
.bm-cross {
    background: #bdc3c7;
}
.bm-menu {
    padding: 55px 0;
    font-size: 1.15em;
}
.burger-menu {
    display: none;
}
.bm-menu-wrap {
    top: 0;
}

@media only screen and (max-width: 767px) {
    #header_main .av-logo-container {
        width: 85%;
        max-width: 85%;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
        float: none;
        padding: 5px;
    }
    #header_main .menu-item {
        display: none;
    }
    .main_menu {
        top: 0;
        height: 80px;
        left: auto;
        right: 0;
        display: block;
        position: absolute;
    }
    .burger-menu {
        display: block;
    }
}